<template>
    <div class="GenericAddOnView ViewWrapper" v-if="addOnData">
        <clv-head-meta :title="$t(addOnData.pageTitleLangKey)"></clv-head-meta>
        <view-header>
            <h1>{{ $t(addOnData.pageTitleLangKey) }}</h1>
            <p class="mt-1" v-if="addOnData.pageSubtitleLangKey">{{ $t(addOnData.pageSubtitleLangKey) }}</p>
        </view-header>
        <view-container>
            <div class="row mt-4 mb-4">
                <div class="col-12 col-md-8">
                    <div class="gk-card">
                        <p class="size-5 weight-4">
                            {{ $t(addOnData.pageCurrentStatePrefixLangKey) }} {{ tenantAccountRequestedAddOnEnabled ? $t('enabled') : $t('disabled') }}
                        </p>
                        <button class="btn btn-danger"
                                v-if="tenantAccountRequestedAddOnEnabled"
                                key="add-on-disable-button"
                                @click="submitAddOnChange">{{ $t('Disable') }}</button>
                        <button class="btn btn-success"
                                v-else
                                key="add-on-enable-button"
                                @click="submitAddOnChange">{{ $t('Enable') }}</button>
                    </div>
                </div>
            </div>
        </view-container>
    </div>
</template>

<script>
import { TenantAccountService } from '@/common/services/api.service';

const addOnDataMap = {
    'politics': {
        pageTitleLangKey: 'Politics Add-On',
        pageSubtitleLangKey: 'politics_add_on_subtitle',
        pageCurrentStatePrefixLangKey: 'Politics add-on is',
        cs3wPathToAddOn: 'politicsAddOnEnabled',
        backEndEnum: 'POLITICS',
        confirmationForEnableLangKey: 'Enable Politics Add-On?',
        confirmationForDisableLangKey: 'Disable Politics Add-On?'
    },
    'gdpr': {
        pageTitleLangKey: 'GDPR Add-On',
        pageSubtitleLangKey: null,
        pageCurrentStatePrefixLangKey: 'GDPR add-on is',
        cs3wPathToAddOn: 'gdprAddOnEnabled',
        backEndEnum: 'GDPR',
        confirmationForEnableLangKey: 'Enable GDPR Add-On?',
        confirmationForDisableLangKey: 'Disable GDPR Add-On?'
    },
    'i18n': {
        pageTitleLangKey: 'i18n Add-On',
        pageSubtitleLangKey: null,
        pageCurrentStatePrefixLangKey: 'i18n add-on is',
        cs3wPathToAddOn: 'i18nAddOnEnabled',
        backEndEnum: 'I18N',
        confirmationForEnableLangKey: 'Enable i18n Add-On?',
        confirmationForDisableLangKey: 'Disable i18n Add-On?'
    }
};

/**
 * GenericAddOnView : Provides common functionality for enabling/disabling add-ons.
 *
 * Supported add-ons:
 * - Politics
 * - GDPR
 * - i18n
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 23 March 2020
 */
export default {
    name: 'GenericAddOnView',
    data () {
        return {
            addOnData: null,
            requestedAddOn: null,
            tenantAccountId: null,
            tenantAccountRequestedAddOnEnabled: false,
            checked: false
        };
    },
    // tenantAccountPoliticsAddOnEnabled: window.cs3w.get('politicsAddOnEnabled', false),
    mounted () {
        // Get requested add-on from route params.
        this.requestedAddOn = this.$route.params.addOn;

        // Check requestedAddOn.
        // if (this.requestedAddOn === null || this.requestedAddOn === undefined) {} // @future : Implement and handle.

        // Get addOnData based on requestedAddOn param.
        if (addOnDataMap.hasOwnProperty(this.requestedAddOn)) {
            this.addOnData = addOnDataMap[this.requestedAddOn];
        } else {} // @future : Implement and handle.

        // Get necessary information from store and cs3w.
        this.tenantAccountId = this.$store.getters['application/tenantAccountId'];
        this.tenantAccountRequestedAddOnEnabled = window.cs3w.get(this.addOnData.cs3wPathToAddOn, false);
    },
    methods: {
        submitAddOnChange () {
            // Get necessary data for request and confirmation.
            const tenantAccountId = this.tenantAccountId;
            const addOnEnum = this.addOnData.backEndEnum;
            const addOnNewState = !this.tenantAccountRequestedAddOnEnabled; // Get the opposite of current state.
            let confirmationMessage;
            // Based on new stage get and trasnlate message.
            if (addOnNewState) {
                confirmationMessage = this.$t(this.addOnData.confirmationForEnableLangKey);
            } else {
                confirmationMessage = this.$t(this.addOnData.confirmationForDisableLangKey);
            }

            // Confirmation.
            let resp = confirm(confirmationMessage);
            if (!resp) return;

            const dto = {
                addOn: addOnEnum,
                tenantId: addOnNewState
            };

            // Perform the Add-On change for this Tenant Account.
            TenantAccountService.setTenantAccountAddOn(tenantAccountId, dto)
                .then(() => {
                    // Reloading the page is necessary.
                    // By doing this, the store will be initialized with the new add-on.
                    // Also, some components (PExpl, Person, etc) will now use different components based on user choice.

                    // 2020-07-17 : Displays global loader while app reloading.
                    this.$store.commit('application/uiInitializingSet', true);
                    this.$store.dispatch('application/uiCheckAndSetReadiness');
                    setTimeout(function () {
                        location.reload();
                    }, 1000);
                }).catch((reason) => {
                    this.toastErrorFromError(reason);
                });
        }
    }
};
</script>

<i18n>
{
    "en": {
        "Add Ons": "Add Ons",

        "enabled": "enabled",
        "disabled": "disabled",

        "Enable": "Enable",
        "Disable": "Disable",

        "Politics Add-On": "Politics Add-On",
        "politics_add_on_subtitle": "Add-On designed to power leaders, organizations, movements, and campaigns.",
        "Politics add-on is": "Politics add-on is",
        "Enable Politics Add-On?": "Enable Politics Add-On?",
        "Disable Politics Add-On?": "Disable Politics Add-On?",

        "GDPR Add-On": "GDPR Add-On",
        "GDPR add-on is": "GDPR add-on is",
        "Enable GDPR Add-On?": "Enable GDPR Add-On?",
        "Disable GDPR Add-On?": "Disable GDPR Add-On?",

        "i18n Add-On": "i18n Add-On",
        "i18n add-on is": "i18n add-on is",
        "Enable i18n Add-On?": "Enable i18n Add-On?",
        "Disable i18n Add-On?": "Disable i18n Add-On?"
    },
    "el": {
        "Add Ons": "Προσθήκες",

        "enabled": "ενεργοποιημένη",
        "disabled": "απενεργοποιημένη",

        "Enable": "Ενεργοποίηση",
        "Disable": "Απενεργοποίηση",

        "Politics Add-On": "Προσθήκη Politics",
        "politics_add_on_subtitle": "Προσθήκη ειδικά σχεδιασμένη για ηγέτες, οργανισμούς, κινήματα και εκστρατείες.",
        "Politics add-on is": "Η Προσθήκη Politics είναι",
        "Enable Politics Add-On?": "Ενεργοποίηση της Προσθήκης Politics;",
        "Disable Politics Add-On?": "Απενεργοποίηση της Προσθήκης Politics;",

        "GDPR Add-On": "Προσθήκη GDPR",
        "GDPR add-on is": "Η Προσθήκη GDPR είναι",
        "Enable GDPR Add-On?": "Ενεργοποίηση της Προσθήκης GDPR;",
        "Disable GDPR Add-On?": "Απενεργοποίηση της Προσθήκης GDPR;",

        "i18n Add-On": "Προσθήκη i18n",
        "i18n add-on is": "Η Προσθήκη i18n είναι",
        "Enable i18n Add-On?": "Ενεργοποίηση της Προσθήκης i18n;",
        "Disable i18n Add-On?": "Απενεργοποίηση της Προσθήκης i18n;"
    }
}
</i18n>
